body {
    margin: 0;
    font-family: 'Lexend', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: linear-gradient(to right top, #f8f4f6, #f6f3f6, #f3f2f5, #f0f1f4, #edf0f3, #ebf0f3, #eaf1f1, #eaf1ef, #edf2ed, #f2f2eb, #f9f1eb, #fef1ee);

    /*background: #0f0c29;  !* fallback for old browsers *!*/
    /*background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  !* Chrome 10-25, Safari 5.1-6 *!*/
    /*background: linear-gradient(to right, #24243e, #302b63, #0f0c29); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/


    /*background: #000;*/

    height: 100%;
    width: 100%;
    min-height: 100vh;
    padding: 0;
}

.app-wrapper {
    padding: 30px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    max-width: 1280px;
    margin: 0 auto;
    width: 80%;
}

.header-wrapper {
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*    border-bottom: 2px solid #ddd;*/
}

 h2.logo {
    margin: 0;
    background: #f12711; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f5af19, #f12711); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f5af19, #f12711); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

 .logo-wrapper .logo {
     margin: 16px 0;
     text-align: center;
 }

.header-wrapper .menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.header-wrapper .menu span {
    padding: 0 16px;
    cursor: pointer;
}

.editor {
    background: #fff;
    padding: 0 0 30px 0;
}

.tab-list-wrapper {
    position: relative;
    padding-left: 45px;
    padding-right: 80px;
    height: 40px;
    border-bottom: 1px solid #ddd;
}

.tab-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;

}

.hide-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

}

.hide-scrollbar::-webkit-scrollbar {
    width: 1px;
}

.hide-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

.hide-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
    height: 1;
}

.tab-list-wrapper .tab {
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
    flex-shrink: 0;
    position: relative;
    border-right: 1px solid #f5f5f5;
    height: 20px;
}

.tab-list-wrapper .tab:hover {
    background: #f5f5f5;
}

.tab .tab-name {
    max-width: 125px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tab .tab-icon {
    border-radius: 100%;
    background: #f5f5f5;
    width: 20px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tab .tab-icon:hover {
    background-color: #1976D2;
    color: #fff;
}

.tab .tab-icon em.material-icons {
    font-size: 14px;
}

.tab::before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 0;
    height: 3px;
    transition: width 0.5s ease;
    background: #1976D2;
}

.tab.active {
    font-weight: 500;
}

.tab.active::before {
    width: 100%;
}

.tab.static {
    position: absolute;
    right: 0;
    background-color: #fff;
    width: auto;
    border-left: 1px solid #ddd;
    top: 0;
}

.tab-navigation {
    position: absolute;
    top: 0;
    padding: 10px 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    box-shadow: -2px 0px 3px 4px rgba(0, 0, 0, 0.09);
    cursor: pointer;
}

.tab-navigation.right {
    right: 45px;
}

.tab-navigation.left {
    left: 0;
}

.mega-title {
    font-size: 7rem;
    margin-bottom: 0;
    /*    background: #7F7FD5; !* fallback for old browsers *!*/
    /*    background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5); !* Chrome 10-25, Safari 5.1-6 *!*/
    /*    background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5); !* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ *!*/
    background: #f12711; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #f5af19, #f12711); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #f5af19, #f12711); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sub-title {
    font-size: 1.5rem;
    color: rebeccapurple;
    margin-top: 0;

    background: #009FFF; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #ec2F4B, #009FFF); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #ec2F4B, #009FFF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

@media only screen and (max-width: 1200px) {
    .mega-title {
        font-size: 4.5rem;
    }
}

.footer-wrapper {
    background: #000428; /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #004e92, #000428); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #004e92, #000428); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    color: #fff;
    padding: 50px 0;
}

.footer-wrapper .footer-description {
    font-size: 14px;
    line-height: 24px;
}

a.link {
    text-decoration: none;
}
a.link:active {
    color: black;
}

.MuiListItem-root:hover {
    background: #f5f5f5;
    cursor: pointer;
}

.MuiListItem-root.active .MuiAvatar-root {
    border: 3px solid #009FFF;
}
